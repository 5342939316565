import React from "react";

function Header() {
  return (
    <header className="header-area header-two">
      <div id="header-sticky" className="menu-area">
        <div className="container-fluid pl-80 pr-80">
          <div className="second-menu">
            <div className="row align-items-center">
              <div className="col-xl-2 col-lg-2">
                <div className="logo">
                  <a href="/">
                    <img src="img/logo/logo.png" alt="logo" />
                  </a>
                </div>
              </div>
              <div className="col-xl-6 col-lg-9 text-right">
                <div className="main-menu">
                  <nav id="mobile-menu">
                    <ul>
                      <li className="has-sub">
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="about.html">About</a>
                      </li>
                      <li>
                        <a href="projects.html">Work</a>
                      </li>
                      <li className="has-sub">
                        <a href="/">Pages</a>
                        <ul>
                          <li>
                            <a href="services.html">Services</a>
                          </li>
                          <li>
                            <a href="pricing.html">Pricing</a>
                          </li>
                          <li>
                            <a href="team.html">Team</a>
                          </li>
                          <li>
                            <a href="faq.html">Faq</a>
                          </li>
                          <li>
                            <a href="blog-details.html">Blog Details</a>
                          </li>
                          <li>
                            <a href="shop.html">Shop</a>
                          </li>
                          <li>
                            <a href="shop-details.html">Shop Details</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="blog.html">News</a>
                      </li>
                      <li>
                        <a href="contact.html">Contact Us</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 text-right d-none d-xl-block mt-20 mb-20">
                <div className="login">
                  <ul>
                    <li>
                      <a href="contact.html" className="btn active">
                        Sign Up{" "}
                        <i className="fa-regular fa-long-arrow-right"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12">
                <div className="mobile-menu"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
