import React from "react";

function Footer() {
  return (
    <footer
      className="footer-bg footer-p"
      style={{
        background: "#212121",
      }}
    >
      <div className="footer-top pt-120 pb-90">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-3 col-lg-3 col-sm-6">
              <div className="footer-widget mb-30">
                <div className="f-widget-title">
                  <h2>
                    <img src="img/logo/f-logo.png" alt="img" />
                  </h2>
                </div>
                <div className="f-contact">
                  <ul>
                    <li>
                      <span>+(123) 456789 00</span>
                    </li>
                    <li>
                      <span>
                        401 Broadway, 24 th floor, Orchard View, London
                      </span>
                    </li>
                    <li>
                      <div className="footer-social">
                        <a href="/">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="/">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-sm-6">
              <div className="footer-widget mb-30">
                <div className="f-widget-title">
                  <h2>Essential</h2>
                </div>
                <div className="footer-link">
                  <ul>
                    <li>
                      <a href="index.html">About us</a>
                    </li>
                    <li>
                      <a href="index.html">Careers</a>
                    </li>
                    <li>
                      <a href="index.html">Prices</a>
                    </li>
                    <li>
                      <a href="index.html">Services</a>
                    </li>
                    <li>
                      <a href="index.html">Get In Touch</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-sm-6">
              <div className="footer-widget mb-30">
                <div className="f-widget-title">
                  <h2>More Pages</h2>
                </div>
                <div className="footer-link">
                  <ul>
                    <li>
                      <a href="index.html">Auto production</a>
                    </li>
                    <li>
                      <a href="index.html"> Investors</a>
                    </li>
                    <li>
                      <a href="index.html">News</a>
                    </li>
                    <li>
                      <a href="index.html">FAQ’s</a>
                    </li>
                    <li>
                      <a href="index.html">Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-sm-6">
              <div className="footer-widget mb-30">
                <div className="f-widget-title">
                  <h2>Latest Post</h2>
                </div>
                <div className="recent-blog-footer">
                  <ul>
                    <li>
                      <div className="thum">
                        <img src="img/blog/s-blogimg-01.png" alt="img" />
                      </div>
                      <div className="text">
                        <a href="blog-details.html">
                          Nothing impossble to need hard work
                        </a>
                        <span>7 March, 2020</span>
                      </div>
                    </li>

                    <li>
                      <div className="thum">
                        <img src="img/blog/s-blogimg-02.png" alt="img" />
                      </div>
                      <div className="text">
                        <a href="blog-details.html">
                          Nothing impossble to need hard work
                        </a>
                        <span>7 March, 2020</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-wrap">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              Copyright 2024 by Printox. All rights reserved
            </div>
            <div className="col-lg-6 text-right text-xl-right">
              <ul>
                <li>
                  <img src="img/logo/f-client-logo.png" alt="img" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
