import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

function Home() {
  return (
    <>
      <Header />
      <main>
        <section
          id="parallax"
          className="slider-area slider-bg second-slider-bg d-flex align-items-center justify-content-center fix"
        >
          <div className="slider-shape ss-one layer" data-depth="0.10">
            <img
              src="https://res.cloudinary.com/dcfotquxt/image/upload/v1731256022/PrintLogs/Images/shop-shopping-retail-2024-09-18-01-52-23-utc-min_nwiuom.jpg"
              alt="shape"
              style={{
                width: "45vh",
              }}
            />
          </div>
          <div className="slider-shape ss-two layer" data-depth="0.30">
            <img
              src="https://res.cloudinary.com/dcfotquxt/image/upload/v1731256424/PrintLogs/Images/sewing-machine-2024-10-20-23-35-18-utc-min_xvi0sv.jpg"
              alt="shape"
            />
          </div>
          <div className="slider-shape ss-three layer" data-depth="0.40">
            <img
              src="https://res.cloudinary.com/dcfotquxt/image/upload/v1731255707/PrintLogs/Images/cropped-view-of-young-artisan-using-blurred-screen-2024-11-09-06-21-16-utc-min_mnuzwi.jpg"
              alt="shape"
              style={{
                width: "45vh",
              }}
            />
          </div>
          <div className="slider-shape ss-four layer" data-depth="0.60">
            <img
              src="https://res.cloudinary.com/dcfotquxt/image/upload/v1731256742/PrintLogs/Images/sewing-machine-in-work-textile-fabric-nobody-2023-11-27-05-32-23-utc-min_fvjmgp.jpg"
              alt="shape"
              style={{
                width: "45vh",
              }}
            />
          </div>
          <div className="slider-shape ss-five layer" data-depth="0.20">
            <img src="img/features/slider_shape05.png" alt="shape" />
          </div>
          <div className="slider-active">
            <div className="single-slider">
              <div className="container">
                <div className="row justify-content-center align-items-center">
                  <div className="col-xl-8 col-lg-9">
                    <div className="slider-content s-slider-content text-center">
                      <h5 data-animation="fadeInUp" data-delay=".4s">
                        Design Your Own
                      </h5>
                      <h2 data-animation="fadeInUp" data-delay=".4s">
                        T-Shirt Printing <strong>Made Easy.</strong>
                      </h2>
                      <p>Create and launch personalized products to sell.</p>
                      <div className="slider-btn mt-50">
                        <a
                          href="contact.html"
                          className="btn ss-btn active mr-15"
                          data-animation="fadeInLeft"
                          data-delay=".4s"
                        >
                          Get Started Today
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="about-area about-p pt-120 pb-120 p-relative fix">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div
                  className="about-content s-about-content wow fadeInRight animated"
                  data-animation="fadeInRight"
                  data-delay=".4s"
                >
                  <div className="section-title second-title pr-90 pb-30">
                    <h2>
                      Selling custom printed T-Shirts <span>Online.</span>
                    </h2>
                    <p className="mt-30">
                      It might seem simple, but many things can go wrong without
                      proper preparation for an effective online store. Make
                      sure you're well-prepared for successful t-shirt printing.
                    </p>
                  </div>
                  <div className="about-content4 mt-30">
                    <div className="row mb-30">
                      <div className="col-md-6">
                        <div className="icon">
                          <img src="img/icon/like.png" alt="an-img-01" />
                        </div>
                        <h3>On-demand service</h3>
                        <p>
                          An experienced builder likely has to be one of them.
                        </p>
                      </div>
                      <div className="col-md-6">
                        <div className="icon">
                          <img src="img/icon/t-shirt.png" alt="an-img-01" />
                        </div>
                        <h3>Quality t-shirt design</h3>
                        <p>
                          Best and High quality products elevating your brand.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div
                  className="s-about-img p-relative text-right pr-30 wow fadeInLeft animated"
                  data-animation="fadeInLeft"
                  data-delay=".4s"
                >
                  <img src="img/features/about_img_02.png" alt="img" />
                  <div className="about-text second-about3 text-left">
                    <img src="img/features/about-reviews.png" alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="services-area pt-120 pb-90 p-relative fix"
          style={{
            background: "rgba(243, 252, 235, 0.5) 100%",
          }}
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="section-title mb-80 text-center">
                  <h5>work process</h5>
                  <h2>
                    How our best printing press <span>working?</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-6">
                <div className="services-item mb-30">
                  <div className="services-thumb">
                    <div className="icon">
                      <img src="img/icon/hw-icon-01.png" alt="img" />
                    </div>
                    <img
                      src="https://res.cloudinary.com/dcfotquxt/image/upload/v1731258529/PrintLogs/Images/oh-what-fun-it-is-to-ride-motorcycle-themed-chris-2023-11-27-05-20-19-utc-min_sgubju.jpg"
                      alt="img"
                      style={{
                        height: "30vh",
                      }}
                    />
                  </div>
                  <div className="services-content">
                    <h3>
                      <a href="single-service.html">Add your t-shirt design</a>
                    </h3>
                    <p>
                      High-quality product images are essential for showcasing
                      the product.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="services-item mb-30">
                  <div className="services-thumb">
                    <div className="icon">
                      <img src="img/icon/hw-icon-02.png" alt="img" />
                    </div>
                    <img
                      src="https://res.cloudinary.com/dcfotquxt/image/upload/v1731256022/PrintLogs/Images/shop-shopping-retail-2024-09-18-01-52-23-utc-min_nwiuom.jpg"
                      alt="img"
                      style={{
                        height: "30vh",
                      }}
                    />
                  </div>
                  <div className="services-content">
                    <h3>
                      <a href="single-service.html">Select cloth quality</a>
                    </h3>
                    <p>
                      High-quality product images are essential for showcasing
                      the product.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="services-item mb-30">
                  <div className="services-thumb">
                    <div className="icon">
                      <img src="img/icon/hw-icon-03.png" alt="img" />
                    </div>
                    <img
                      src="https://res.cloudinary.com/dcfotquxt/image/upload/v1731258980/PrintLogs/Images/woman-paying-for-cocktail-with-credit-card-at-bar-2023-11-27-05-21-38-utc-min_xotuj0.jpg"
                      alt="img"
                      style={{
                        height: "30vh",
                      }}
                    />
                  </div>
                  <div className="services-content">
                    <h3>
                      <a href="single-service.html">Make your payment</a>
                    </h3>
                    <p>
                      High-quality product images are essential for showcasing
                      the product.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="home-product-slider pt-120 pb-40 fix"
          style={{
            backgroundImage: 'url("img/bg/product-bg.png")',
            backgroundSize: "contain",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="section-title center-align mb-80 wow fadeInDown animated"
                  data-animation="fadeInDown"
                  data-delay=".4s"
                >
                  <h5>Products</h5>
                  <h2>
                    Hand-picked <span>products</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-12">
                <div className="product mb-40">
                  <div className="product__img">
                    <a href="shop-details.html">
                      <img
                        src="https://res.cloudinary.com/dcfotquxt/image/upload/v1731259773/PrintLogs/Images/colorful-t-shirts-arranged-in-a-row-on-cloth-rack-2023-11-27-05-26-43-utc-min_warhnr.jpg"
                        alt=""
                      />
                    </a>
                    <div className="product-action text-center">
                      <a href="shop-details.html">Add Cart</a>
                    </div>
                  </div>
                  <div className="product__content pt-30">
                    <h4 className="pro-title">
                      <a href="shop-details.html">T-Shirt & Tops</a>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="product mb-40">
                  <div className="product__img">
                    <a href="shop-details.html">
                      <img
                        src="https://res.cloudinary.com/dcfotquxt/image/upload/v1731260125/PrintLogs/Images/stack-of-autumn-basic-turtlenecks-in-trendy-colors-2023-11-27-05-16-22-utc-min_jts9sp.jpg"
                        alt=""
                      />
                    </a>
                    <div className="product-action text-center">
                      <a href="shop-details.html">Add Cart</a>
                    </div>
                  </div>
                  <div className="product__content pt-30">
                    <h4 className="pro-title">
                      <a href="shop-details.html">Sweatshirts & Fleece</a>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="product mb-40">
                  <div className="product__img">
                    <a href="shop-details.html">
                      <img
                        src="https://res.cloudinary.com/dcfotquxt/image/upload/v1731368336/PrintLogs/Images/box-with-beanies-and-cotton-on-white-background-2023-11-27-05-25-47-utc-min_ay6z0h.jpg"
                        alt=""
                      />
                    </a>
                    <div className="product-action text-center">
                      <a href="shop-details.html">Add Cart</a>
                    </div>
                  </div>
                  <div className="product__content pt-30">
                    <h4 className="pro-title">
                      <a href="shop-details.html">Headwear</a>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="product mb-40">
                  <div className="product__img">
                    <a href="shop-details.html">
                      <img
                        src="https://res.cloudinary.com/dcfotquxt/image/upload/v1731260473/PrintLogs/Images/stylish-eco-bags-and-twigs-on-white-background-2023-11-27-05-10-58-utc-min_rjy5kt.jpg"
                        alt=""
                      />
                    </a>
                    <div className="product-action text-center">
                      <a href="shop-details.html">Add Cart</a>
                    </div>
                  </div>
                  <div className="product__content pt-30">
                    <h4 className="pro-title">
                      <a href="shop-details.html">Bags</a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="event event03 pb-80 p-relative fix"
          style={{
            backgroundImage: "url('./img/bg/faq-bg.png')",
            backgroundPosition: "center right",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-6 col-md-12 wow fadeInUp animated"
                data-animation="fadeInUp"
                data-delay=".4s"
              >
                <div className="faq-img">
                  <img src="img/bg/faq-img.png" alt="img" />
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12 wow fadeInUp animated"
                data-animation="fadeInUp"
                data-delay=".4s"
              >
                <div
                  className="faq-wrap pl-100 wow fadeInUp animated"
                  data-animation="fadeInUp"
                  data-delay=".4s"
                >
                  <div className="section-title mb-80 text-left">
                    <h5>faq & ans.</h5>
                    <h2>
                      Get your every q.
                      <br />
                      and <span>answers</span>
                    </h2>
                  </div>
                  <div className="accordion" id="accordionExample">
                    <div className="card">
                      <div className="card-header" id="headingThree">
                        <h2 className="mb-0">
                          <button
                            className="faq-btn"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                          >
                            how can i make refund?
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseThree"
                        className="collapse show"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="card-body">
                          In addition to essential product information,
                          web2print storefront product detail pages also feature
                          customer reviews and ratings, which help.
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h2 className="mb-0">
                          <button
                            className="faq-btn collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                          >
                            where i can find a good catalogue?
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseOne"
                        className="collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="card-body">
                          In addition to essential product information,
                          web2print storefront product detail pages also feature
                          customer reviews and ratings, which help.
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h2 className="mb-0">
                          <button
                            className="faq-btn collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                          >
                            who is the best person in press?
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseTwo"
                        className="collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="card-body">
                          In addition to essential product information,
                          web2print storefront product detail pages also feature
                          customer reviews and ratings, which help.
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingFour">
                        <h2 className="mb-0">
                          <button
                            className="faq-btn collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                          >
                            where i have to made request for support?
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseFour"
                        className="collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="card-body">
                          In addition to essential product information,
                          web2print storefront product detail pages also feature
                          customer reviews and ratings, which help.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          className="brand-area pt-120 pb-95"
          style={{
            backgroundImage: 'url("img/bg/brand-bg.png")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-8 col-md-12">
                <div
                  className="section-title center-align mb-80 text-center wow fadeInDown animated"
                  data-animation="fadeInDown"
                  data-delay=".4s"
                >
                  <h5>our sponsors</h5>
                  <h2>
                    We’re Trusted by 240+ firms & Departments&nbsp;
                    <span>Across the Globe</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 text-center">
                <ul className="mb-50">
                  <li>
                    <img src="img/brand/client-log-01.png" alt="img" />
                  </li>
                  <li>
                    <img src="img/brand/client-log-02.png" alt="img" />
                  </li>
                  <li>
                    <img src="img/brand/client-log-03.png" alt="img" />
                  </li>
                  <li>
                    <img src="img/brand/client-log-04.png" alt="img" />
                  </li>
                  <li>
                    <img src="img/brand/client-log-05.png" alt="img" />
                  </li>
                  <li>
                    <img src="img/brand/client-log-06.png" alt="img" />
                  </li>
                  <li>
                    <img src="img/brand/client-log-07.png" alt="img" />
                  </li>
                  <li>
                    <img src="img/brand/client-log-08.png" alt="img" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <section className="feature-area p-relative">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-12 col-sm-12 text-center">
                <div className="feature-box">
                  <div className="icon">
                    <img src="img/icon/f-global-network.png" alt="img" />
                  </div>
                  <div className="text text-left">
                    <h5>
                      Worldwide <strong>shipping</strong>
                    </h5>
                    <p>Shipping all over the world.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 text-center">
                <div className="feature-box">
                  <div className="icon">
                    <img src="img/icon/f-flower.png" alt="img" />
                  </div>
                  <div className="text text-left">
                    <h5>
                      On demand <strong>service</strong>
                    </h5>
                    <p>No minimums, on demand service.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 text-center">
                <div className="feature-box">
                  <div className="icon">
                    <img src="img/icon/f-tshirt.png" alt="img" />
                  </div>
                  <div className="text text-left">
                    <h5>
                      Custom t-shirt <strong>design</strong>
                    </h5>
                    <p>Designs and customize 400+ products.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 text-center">
                <div className="feature-box">
                  <div className="icon">
                    <img src="img/icon/f-support.png" alt="img" />
                  </div>
                  <div className="text text-left">
                    <h5>
                      Publish &&nbsp;<strong>Earn</strong>
                    </h5>
                    <p>Connect your products to your online store.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          className="scrollbox4 pt-120 pb-60"
          style={{
            background: "#212121",
          }}
        >
          <div className="scrollbox scrollbox--secondary scrollbox--reverse">
            <div className="scrollbox__item">
              <div className="section-t">
                <img src="img/bg/scrollbox-text.png" alt="img" />
              </div>
            </div>
            <div className="scrollbox__item">
              <div className="section-t">
                <img src="img/bg/scrollbox-text.png" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Home;
